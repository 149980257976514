<template>
  <SidebarCheckboxList
    v-if="filteredProducts.length"
    :title="$t('Product')"
    :search-value="searchValue"
    :items="filteredProducts"
    :items-selected="[]"
    :as-links="true"
    @search-input="searchValue = $event"
  >
    <template #label="{ item }">
      <div class="product-links__product-label">
        <ProductImage class="product-links__image-wrapper" :name="item.name_en" />
        <span>{{ item.name }}</span>
      </div>
    </template>
  </SidebarCheckboxList>
</template>

<script>
import ProductImage from '@/components/common/ProductImage.vue'
import { mapGetters, mapState } from 'vuex'
import SidebarCheckboxList from '../sidebar/SidebarCheckboxList.vue'

export default {
  name: 'ProductFilter',
  components: {
    ProductImage,
    SidebarCheckboxList,
  },
  data: () => {
    return {
      searchValue: '',
    }
  },
  computed: {
    ...mapState('product', ['products', 'groups']),
    ...mapState('marketCatalog', ['filterMetadata']),
    ...mapGetters('product', ['productsByGroup']),
    filteredProducts() {
      const availableProductsSet = new Set((this.filterMetadata?.products || []).map(p => p.id))

      return this.currentGroup
        ? this.productsByGroup(this.currentGroup.id)
            .filter(p => availableProductsSet.has(p.id))
            .map(product => ({
              ...product,
              route: {
                name: 'productsByProduct',
                params: { productCategory: this.currentGroup.routeName, productName: product.routeName },
              },
            }))
        : this.products.filter(p => availableProductsSet.has(p.id))
    },
    currentGroup() {
      return this.groups.find(g => g.routeName === this.$route.params.productCategory)
    },
  },
  watch: {
    currentGroup() {
      this.searchValue = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.product-links {
  &__product-label {
    display: flex;
    align-items: center;
  }

  &__image-wrapper {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}
</style>
