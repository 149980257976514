import { addRouteNameToSellerProduct } from '@/helpers/sellerProduct/addRouteNameToSellerProduct'
import Vue from 'vue'

const handleError = e => {
  if (e?.message === 'canceled') {
    return
  }

  throw e
}

const initialFilterMetadata = {
  categories: [],
  countries: [],
  groups: [],
  sorts: [],
  products: [],
}

export default {
  namespaced: true,
  state: {
    sellerProducts: [],
    totalPages: 1,
    filterMetadata: initialFilterMetadata,
  },
  actions: {
    async listSellerProducts({ commit }, { signal, ...params }) {
      try {
        const response = await Vue.$http.get('/profile_product/products/', {
          params,
          showLoadingBar: false,
          signal,
        })

        const { results, total_pages } = response

        commit('setSellerProducts', results)
        commit('setTotalPages', total_pages)
        return results
      } catch (e) {
        handleError(e)
      }
    },
    async listMoreSellerProducts({ state, commit }, { signal, ...params }) {
      try {
        const response = await Vue.$http.get('/profile_product/products/', {
          params,
          showLoadingBar: false,
          signal,
        })

        const { results, total_pages } = response

        // На всякий проверяем нет ли дубликатов в списке
        const existingProductIds = new Set(state.sellerProducts.map(p => p.id))
        const newProducts = results.filter(p => !existingProductIds.has(p.id))

        commit('setSellerProducts', [...state.sellerProducts, ...newProducts])
        commit('setTotalPages', total_pages)
        return results
      } catch (e) {
        handleError(e)
      }
    },
    async getFiltersData({ commit }, { ...params }) {
      const response = await Vue.$http.get('/profile_product/products/', {
        params,
        showLoadingBar: false,
      })

      const { categories, countries, groups, sorts, products } = response

      commit('setFilterMetadata', {
        categories,
        countries,
        groups,
        products,
        sorts,
      })
    },
    clearSellerProducts({ commit }) {
      commit('setSellerProducts', [])
      commit('setFilterMetadata', initialFilterMetadata)
      commit('setTotalPages', 1)
    },
  },
  mutations: {
    setSellerProducts(state, data) {
      state.sellerProducts = data.map(addRouteNameToSellerProduct)
    },
    setFilterMetadata(state, data) {
      state.filterMetadata = data
    },
    setTotalPages(state, pages) {
      state.totalPages = pages
    },
  },
}
