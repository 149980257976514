<template>
  <SidebarCheckboxList
    v-if="filteredProducts.length"
    :title="$t('Product')"
    :search-value="searchValue"
    :items="filteredProducts"
    :items-selected="productsSelected"
    @search-input="searchValue = $event"
    @select="select"
  >
    <template #label="{ item }">
      <div class="product-filter__product-label">
        <ProductImage class="product-filter__image-wrapper" :name="item.name_en" />
        <span>{{ item.name }}</span>
      </div>
    </template>
  </SidebarCheckboxList>
</template>

<script>
import ProductImage from '@/components/common/ProductImage.vue'
import { mapGetters, mapState } from 'vuex'
import SidebarCheckboxList from '../sidebar/SidebarCheckboxList.vue'

export default {
  name: 'ProductFilter',
  components: {
    ProductImage,
    SidebarCheckboxList,
  },
  data: () => {
    return {
      searchValue: '',
    }
  },
  computed: {
    ...mapState('product', ['products', 'groups']),
    ...mapState('filtration', ['productsSelected']),
    ...mapGetters('product', ['productsByGroup']),
    ...mapState('marketCatalog', ['filterMetadata']),
    filteredProducts() {
      const availableProductsSet = new Set((this.filterMetadata?.products || []).map(p => p.id))

      return this.currentGroup
        ? this.productsByGroup(this.currentGroup.id).filter(p => availableProductsSet.has(p.id))
        : this.products.filter(p => availableProductsSet.has(p.id))
    },
    currentGroup() {
      return this.groups.find(g => g.routeName === this.$route.params.productCategory)
    },
    productsQuery() {
      return this.$route.query?.products
    },
  },
  watch: {
    currentGroup() {
      this.searchValue = ''
    },
    productsQuery: {
      async handler() {
        if (!this.products.length) {
          await this.$store.dispatch('product/listProducts', { params: { page_size: 1000 } })
        }

        if (!this.productsQuery) {
          if (this.productsSelected.length) {
            this.$store.dispatch('filtration/setProductsSelected', [])
          }
          return
        }

        const productsSet = new Set(this.productsQuery.split(','))

        this.$store.dispatch(
          'filtration/setProductsSelected',
          this.products.filter(p => productsSet.has(p.routeName)),
        )
      },
      immediate: true,
    },
  },
  methods: {
    select(product) {
      let newQuery = {
        ...this.$route.query,
      }

      if (!this.productsQuery) {
        newQuery.products = product.routeName
        this.$router.push({ query: newQuery })
        return
      }

      const queryProducts = this.productsQuery.split(',')

      if (queryProducts.some(p => p === product.routeName)) {
        newQuery.products = queryProducts.filter(item => item !== product.routeName).join(',')
      } else {
        newQuery.products = [...queryProducts, product.routeName].join(',')
      }

      if (!newQuery.products.length) {
        delete newQuery.products
      }

      this.$router.push({ query: newQuery })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.product-filter {
  &__product-label {
    display: flex;
    align-items: center;
  }

  &__image-wrapper {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}
</style>
